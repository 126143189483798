export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILED = "GET_ORDERS_FAILED";

export const GET_ORDER_MANUFAC_REQUEST = "GET_ORDER_MANUFAC_REQUEST";
export const GET_ORDER_MANUFAC_SUCCESS = "GET_ORDER_MANUFAC_SUCCESS";
export const GET_ORDER_MANUFAC_FAILED = "GET_ORDER_MANUFAC_FAILED";

export const DECLINE_ORDER_REQUEST = "DECLINE_ORDER_REQUEST";
export const DECLINE_ORDER_SUCCESS = "DECLINE_ORDER_SUCCESS";
export const DECLINE_ORDER_FAILED = "DECLINE_ORDER_FAILED";

export const GET_ORDER_IMAGE_ITEMS_REQUEST = 'GET_ORDER_IMAGE_ITEMS_REQUEST'
export const GET_ORDER_IMAGE_ITEMS_SUCCESS = 'GET_ORDER_IMAGE_ITEMS_SUCCESS'
export const GET_ORDER_IMAGE_ITEMS_FAILED = 'GET_ORDER_IMAGE_ITEMS_FAILED'
