export const GET_POOL_SIZE_REQUEST = "GET_POOL_SIZE_REQUEST";
export const GET_POOL_SIZE_SUCCESS = "GET_POOL_SIZE_SUCCESS";
export const GET_POOL_SIZE_FAILED = "GET_POOL_SIZE_FAILED";

export const GET_POOL_SHAPE_REQUEST = "GET_POOL_SHAPE_REQUEST";
export const GET_POOL_SHAPE_SUCCESS = "GET_POOL_SHAPE_SUCCESS";
export const GET_POOL_SHAPE_FAILED = "GET_POOL_SHAPE_FAILED";

export const GET_POOL_COLOUR_REQUEST = "GET_POOL_COLOUR_REQUEST";
export const GET_POOL_COLOUR_SUCCESS = "GET_POOL_COLOUR_SUCCESS";
export const GET_POOL_COLOUR_FAILED = "GET_POOL_COLOUR_FAILED";

export const GET_SKIMMER_REQUEST = "GET_SKIMMER_REQUEST";
export const GET_SKIMMER_SUCCESS = "GET_SKIMMER_SUCCESS";
export const GET_SKIMMER_FAILED = "GET_SKIMMER_FAILED";

export const GET_POOL_LIGHTS_REQUEST = "GET_POOL_LIGHTS_REQUEST";
export const GET_POOL_LIGHTS_SUCCESS = "GET_POOL_LIGHTS_SUCCESS";
export const GET_POOL_LIGHTS_FAILED = "GET_POOL_LIGHTS_FAILED";

export const GET_TRANSFORMER_REQUEST = "GET_TRANSFORMER_REQUEST";
export const GET_TRANSFORMER_SUCCESS = "GET_TRANSFORMER_SUCCESS";
export const GET_TRANSFORMER_FAILED = "GET_TRANSFORMER_FAILED";

export const GET_SPA_JETS_REQUEST = "GET_SPA_JETS_REQUEST";
export const GET_SPA_JETS_SUCCESS = "GET_SPA_JETS_SUCCESS";
export const GET_SPA_JETS_FAILED = "GET_SPA_JETS_FAILED";

export const GET_PIPE_REQUEST = "GET_PIPE_REQUEST";
export const GET_PIPE_SUCCESS = "GET_PIPE_SUCCESS";
export const GET_PIPE_FAILED = "GET_PIPE_FAILED";

export const GET_HEATING_REQUEST = "GET_HEATING_REQUEST";
export const GET_HEATING_SUCCESS = "GET_HEATING_SUCCESS";
export const GET_HEATING_FAILED = "GET_HEATING_FAILED";

export const GET_BLANKET_ROLLER_REQUEST = "GET_BLANKET_ROLLER_REQUEST";
export const GET_BLANKET_ROLLER_SUCCESS = "GET_BLANKET_ROLLER_SUCCESS";
export const GET_BLANKET_ROLLER_FAILED = "GET_BLANKET_ROLLER_FAILED";

export const GET_HANDOVER_KIT_REQUEST = "GET_HANDOVER_KIT_REQUEST";
export const GET_HANDOVER_KIT_SUCCESS = "GET_HANDOVER_KIT_SUCCESS";
export const GET_HANDOVER_KIT_FAILED = "GET_HANDOVER_KIT_FAILED";

export const GET_POOL_SALT_REQUEST = "GET_POOL_SALT_REQUEST";
export const GET_POOL_SALT_SUCCESS = "GET_POOL_SALT_SUCCESS";
export const GET_POOL_SALT_FAILED = "GET_POOL_SALT_FAILED";

export const GET_MANUFACTURING_OPTIONS_REQUEST =
  "GET_MANUFACTURING_OPTIONS_REQUEST";
export const GET_MANUFACTURING_OPTIONS_SUCCESS =
  "GET_MANUFACTURING_OPTIONS_SUCCESS";
export const GET_MANUFACTURING_OPTIONS_FAILED =
  "GET_MANUFACTURING_OPTIONS_FAILED";

export const GET_ORDER_POLICY_REQUEST = "GET_ORDER_POLICY_REQUEST";
export const GET_ORDER_POLICY_SUCCESS = "GET_ORDER_POLICY_SUCCESS";
export const GET_ORDER_POLICY_FAILED = "GET_ORDER_POLICY_FAILED";  
