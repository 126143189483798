export const DIAGRAM_SUBMIT_REQUEST = "DIAGRAM_SUBMIT_REQUEST";
export const DIAGRAM_SUBMIT_SUCCESS = "DIAGRAM_SUBMIT_SUCCESS";
export const DIAGRAM_SUBMIT_FAILED = "DIAGRAM_SUBMIT_FAILED";

export const SIGNATURE_SUBMIT_REQUEST = "SIGNATURE_SUBMIT_REQUEST";
export const SIGNATURE_SUBMIT_SUCCESS = "SIGNATURE_SUBMIT_SUCCESS";
export const SIGNATURE_SUBMIT_FAILED = "SIGNATURE_SUBMIT_FAILED";

export const SIGNATURE_RE_SUBMIT_REQUEST = "SIGNATURE_RE_SUBMIT_REQUEST";
export const SIGNATURE_RE_SUBMIT_SUCCESS = "SIGNATURE_RE_SUBMIT_SUCCESS";
export const SIGNATURE_RE_SUBMIT_FAILED = "SIGNATURE_RE_SUBMIT_FAILED";

export const CLEAR_SIGNATURE_REQUEST = "CLEAR_SIGNATURE_REQUEST";
export const CLEAR_SIGNATURE_SUCCESS = "CLEAR_SIGNATURE_SUCCESS";
export const CLEAR_SIGNATURE_FAILED = "CLEAR_SIGNATURE_FAILED";

export const FINAL_BOARD_SUBMIT_REQUEST = "FINAL_BOARD_SUBMIT_REQUEST";
export const FINAL_BOARD_SUBMIT_SUCCESS = "FINAL_BOARD_SUBMIT_SUCCESS";
export const FINAL_BOARD_SUBMIT_FAILED = "FINAL_BOARD_SUBMIT_FAILED";

export const FORM_SUBMIT_REQUEST = "FORM_SUBMIT_REQUEST";
export const FORM_SUBMIT_SUCCESS = "FORM_SUBMIT_SUCCESS";
export const FORM_SUBMIT_FAILED = "FORM_SUBMIT_FAILED";

export const CLEAR_FINAL_STEP_FORM_SUCCESS = "CLEAR_FINAL_STEP_FORM_SUCCESS";
